import axios from 'axios';

const getOrderList = (offset, limit, beginTime, endTime,param) => axios.get("/order", {params: {offset,limit,beginTime,endTime,...param}});
const getOrder = (token) => axios.get("/open/order/quote/" + token);
const deleteOrder = (id,reason) => axios.delete("/order/" + id,{params:{reason:reason}});
const forceDeleteOrder = (id,reason) => axios.delete("/order/force/" + id);
const addOrder = (form) => axios.post("/order", form);
const updateOrder = (form) => axios.put("/order", form);
const estimateLogicCheck = (form) => axios.post("/order/logic",form);
//计算起始地与目的地之间的往返费用与油钱
const calcFuelCost = (origin,destination) => axios.get("/order/cost/fuel?origin=" + origin + "&destination=" + destination);

export {
	getOrderList,
	getOrder ,
	deleteOrder ,
	addOrder ,
	updateOrder,
	forceDeleteOrder,
	estimateLogicCheck,
	calcFuelCost
}