// import CryptoJS from 'crypto-js';
import axios from 'axios';
	
function uuid(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [], i;
    radix = radix || chars.length;
 
    if (len) {
      // Compact form
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
    } else {
      // rfc4122, version 4 form
      var r;
 
      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';
 
      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random()*16;
          uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
        }
      }
    }
 
    return uuid.join('');
}

//树形菜单排序，CommonAside组件使用用此方法
function findParent(row,permissions){
	if(row.parentId != 0 && row.type==1){			//增加查询（type=1）的页面链接显示在CommonAside菜单栏中，删除更新(type==2)的链接显示在Layout.el-main.router-view查询结果页面中。有时也可以将添加操作type改为2放在查询结果页面中
		for (var i = 0; i < permissions.length; i++) {
			if(row.parentId==permissions[i].id){
				if(!permissions[i].child){
					permissions[i].child=[];
				}
				permissions[i].child.push(row);
			}
		}
	}
}

function removeHTMLTag(str) {  
	var content = str;
    str = str.replace(/<\/?[^>]*>/g,''); //去除HTML tag  
    str = str.replace(/[ | ]*\n/g,'\n'); //去除行尾空白  
    str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行  
    str=str.replace(/ /ig,'');//去掉   
    
    if(str.length>60){
    	str = str.substr(0,60) +"...";
    }
    return str;  
}  

function isBlank(value){
	if(
		value==undefined || 
		value==null || 
		value == "" || 
		(typeof value=="string" && value.trim()=="") || 
		(Array.isArray(value) && value.length==0) 
	)
	{
		return true;
	}else{
		return false;
	}
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
function handleTree(data, id, parentId, children, rootId) {
	id = id || 'id'
	parentId = parentId || 'parentId'
	children = children || 'children'
	rootId = rootId || Math.min.apply(Math, data.map(item => { return item[parentId] })) || 0
	//对源数据深度克隆
	const cloneData = JSON.parse(JSON.stringify(data))
	//循环所有项
	const treeData = cloneData.filter(father => {
		let branchArr = cloneData.filter(child => {
			//返回每一项的子级数组
			return father[id] === child[parentId]
		});
		branchArr.length > 0 ? father.children = branchArr : '';
		//返回第一层
		return father[parentId] === rootId;
	});
	return treeData != '' ? treeData : data;
}

//部门树 treeselect 数据结构调整
function normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.deptName,
        children: node.children
      };
}

function resetToUndefined(obj){
	for(var k in obj){
		obj[k]=undefined;
	}
}

function encodeParams(obj){
	var query="";
	for(var k in obj){
		if(! isBlank(obj[k])){
			query+=("&"+k+"="+obj[k])
		}
	}
	if(query.endsWith("&")){
		query=query.substring(0,query.length-1);
	}
	return query;
}

/**
 * 流程实例id是uuid字符串，但客户要求显示数字排序id,方便观察。解决办法：在不修改后台代码与数据库的情况下，根据pageNum、pageSize、createTime三个字段生成一个数字排序id。但此处暂时没有乃至createTime排序
 */
function createId(proInstList){
	var baseNum=(this.queryParams.pageNum-1)*this.queryParams.pageSize;
	for (var i = proInstList.length-1,j=0; i >= 0; i--,j++) {
		proInstList[i].id=baseNum+j+1;
	}
}
	
// 附件 icon 选择
function getFileIcon(suffix) {
	if (suffix) {
		if (suffix.endsWith(".png")||suffix.endsWith(".PNG")||
		suffix.endsWith(".jpg")||suffix.endsWith(".JPG")||
		suffix.endsWith(".jpeg")||suffix.endsWith(".JPEG")) {
			return "el-icon-picture";
		} else {
			return "el-icon-paperclip";
		}
	}
	return "";
}

//递归函数，处理树形结构，从menu/components/Datatable.vue引入
function recursion(row,cackball){
	cackball(row)
	if(row.child){
		for (var i = 0; i < row.child.length; i++) {
			recursion(row.child[i],cackball);
		}
	}
}

// 递归剔除响应数据中的null字段，主要解决的问题是el-input-number会将null值显示为0的问题
function deleteNullField(obj){
	if(Array.isArray(obj)){
		for (var i = 0; i < obj.length; i++) {
			deleteNullField(obj[i]);
		}
	}else if(typeof obj == "object"){
		for(var k in obj)
		{
			if(obj[k] == null){
				delete obj[k];
			}else if(typeof obj[k] == "object"){
				deleteNullField(obj[k])
			}
		}
	}
}

function dayOfWeek(day){
	switch(day){
		case 1:
		return "Monday";
		case 2:
		return "Tuesday";
		case 3:
		return "Wednesday";
		case 4:
		return "Thursday";
		case 5:
		return "Friday";
		case 6:
		return "Saturday";
		case 7:
		return "Sunday";
		default:
		return null;
	}
}

function formatLineWrap(note){
	if(note){
		return note.replaceAll("\n","<br>");
	}else{
		return null;
	}
}

function getPolicyLink(id){
	return location.origin+"/#/p/"+id;
}
export {
	uuid,
	removeHTMLTag,
	isBlank,
	handleTree,
	normalizer,
	resetToUndefined,
	encodeParams,
	createId,
	getFileIcon,
	// encryptByDES,
	// decryptByDES,
	// encryptByAES,
	// decryptByAES,
	// en,
	recursion,
	deleteNullField,
	dayOfWeek,
	formatLineWrap,
	getPolicyLink
	
}